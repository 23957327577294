import React from 'react';
import Layout from '../components/Layout';

const About = () => {
    return (
        <Layout>
            <article>
                <h1 className="title is-1">About</h1>
                <p>Such wow. Very React</p>
                <img src="https://i.kym-cdn.com/entries/icons/mobile/000/013/564/doge.jpg" />
            </article>
        </Layout>
    );
};

export default About;
